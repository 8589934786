import React from 'react';
import { Image } from '@appscore/web-components';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { Routes } from '@app/config/routes';
import { Constants } from '@app/config/constants';
import Button from '@pedidosya/web-fenix/atoms/Button';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import RetentionImage from '@app/assets/images/retention.svg';
import HighDelay from '@app/assets/images/high_delay.svg';
import Shape from '@app/components/Shape';
/**
 * Styled Components
 */
import {
  TitleContainerDown,
  ButtonContainer,
  ButtonActionsContainer,
  TitleDown,
  TitleUp,
  TitleUpContainer,
  Container,
} from './Retention.styles';
/**
 * Components
 *
 */
import ImageContainer from '@app/components/ImageContainer';
import { NumberFormatter } from '@app/components/NumberFormatter';
import { LayoutWeb } from '@app/components/LayoutWeb';
import { LayoutMobile } from '@app/components/LayoutMobile';
/**
 * Translations
 *
 */
import Translations from './Retention.translations';
/**
 * Hooks
 */
import { useRetention } from './useRetention';
import { useIsWeb } from '@app/hooks/useIsWeb';
import { useWindowSize } from '@app/hooks/useWindowSize';

const Retention = () => {
  const { formatMessage } = useIntl();
  const { onCancel, onBack, loading, error, cancellation, rules, loadingCancel, flow } =
    useRetention();
  const { height } = useWindowSize();
  const isWeb = useIsWeb();
  const Layout = isWeb ? LayoutWeb : LayoutMobile;

  if (loading) {
    return (
      <div data-testid="loader">
        <PeyaLoader position="center" />
      </div>
    );
  }

  if (rules && rules?.navigation?.onPageLoadRedirect) {
    return <Redirect to={`${rules?.navigation?.onPageLoadRedirect}?flow=${flow}`} />;
  }

  if (error) {
    return <Redirect to={Routes.CANCELLATION_HELP} />;
  }

  return (
    <Layout
      data-testid="question_container"
      onBack={onBack}
      showMock={true}
      showCanvan={false}
      iconType="back"
    >
      <Container height={height} isWeb={isWeb}>
        <div>
          <ImageContainer>
            <Image
              src={flow === Constants.HIGH_DELAY_FLOW ? HighDelay : RetentionImage}
              alt="Bag"
            />
          </ImageContainer>
          <TitleUpContainer>
            {rules?.mainText ? (
              <TitleUp
                component="h3"
                token="font-headline-highcontrast-small"
                color="text-color-primary"
                isWeb={isWeb}
              >
                {formatMessage(Translations[rules?.mainText], {
                  name: cancellation?.orderDetail?.user?.name,
                })}
              </TitleUp>
            ) : (
              <TitleUp
                component="h3"
                token="font-headline-highcontrast-small"
                color="text-color-primary"
                isWeb={isWeb}
              >
                {formatMessage(Translations.mainSecondTitle)}
              </TitleUp>
            )}
          </TitleUpContainer>
          <TitleContainerDown>
            <NumberFormatter
              country={cancellation?.orderDetail?.countryCode}
              amount={cancellation?.orderDetail?.totalAmount}
            >
              {(amount) => {
                return rules?.mainTitle ? (
                  <TitleDown
                    component="p"
                    token="font-label-midcontrast-medium"
                    color="text-color-secondary"
                    isWeb={isWeb}
                  >
                    {formatMessage(Translations[rules?.mainTitle], { value: amount })}
                  </TitleDown>
                ) : (
                  <TitleDown
                    component="p"
                    token="font-label-midcontrast-medium"
                    color="text-color-secondary"
                    isWeb={isWeb}
                  >
                    {formatMessage(Translations.mainTitle, { value: amount })}
                  </TitleDown>
                );
              }}
            </NumberFormatter>
          </TitleContainerDown>
          {cancellation?.orderDetail?.quoteId && (
            <ButtonActionsContainer data-testid="buttons_container" isWeb={isWeb}>
              <ButtonContainer isWeb={isWeb}>
                <Button
                  type="button"
                  hierarchy="primary"
                  label={formatMessage(Translations.cacncel)}
                  onClick={(e) => onCancel(e)}
                  fullWidth={true}
                />
              </ButtonContainer>
              <ButtonContainer isWeb={isWeb}>
                <Button
                  type="button"
                  hierarchy="secondary"
                  label={formatMessage(Translations.backToOrder)}
                  onClick={onBack}
                  fullWidth={true}
                />
              </ButtonContainer>
            </ButtonActionsContainer>
          )}
        </div>
        {loadingCancel && (
          <Shape>
            <PeyaLoader position="center" />;
          </Shape>
        )}
      </Container>
    </Layout>
  );
};

export default Retention;
