import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Routes } from '@app/config/routes';

function ProtectedRoute({ children, condition, ...restOfProps }) {
  return (
    <Route
      {...restOfProps}
      render={() => (condition ? <>{children}</> : <Redirect to={Routes.NOT_FOUND} />)}
    />
  );
}

export default ProtectedRoute;
