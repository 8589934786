import { useMemo } from 'react';
import { useCancellationContext } from '@app/contexts/Cancellation';
import { cancelQuote } from '@app/service/cancellationService';
import { Routes } from '@app/config/routes';
import { useHistory } from 'react-router-dom';
import { useUrlQuery } from '@app/hooks/useUrlQuery';
import { useTracker } from '@app/contexts/TrackerProvider';
import { TrackEvents } from '@app/config/trackEvents';
import { Constants } from '@app/config/constants';
import { sentryHub } from '@app/utils/sentry';
import { useMutation } from 'react-query';

export const useConfirmQuote = () => {
  const { cancellation, dispatch } = useCancellationContext();
  const { push } = useHistory();
  const { gtmTrack } = useTracker();
  const query = useUrlQuery();
  const flow = useMemo(() => query.get('flow') || Constants.ORIGEN, []);

  const { isLoading, mutate, isError } = useMutation({
    mutationKey: ['confirmQuote', cancellation?.orderDetail],
    mutationFn: () => {
      const { orderId, quoteId } = cancellation?.orderDetail;
      const { globalRejectionReason, code } = cancellation?.reasonSelected;
      const extra = { ...cancellation?.extra, orderRejectionInternalReason: code };
      if (cancellation?.selectedRefundMethod) {
        extra['selectedRefundMethod'] = cancellation?.selectedRefundMethod;
      }
      return new Promise(async (resolve, rejected) => {
        try {
          const result = await cancelQuote(orderId, quoteId, globalRejectionReason, flow, extra);
          setTimeout(() => {
            resolve(result);
          }, Constants.TIME_AWAITED_CONFIRM);
        } catch (e) {
          rejected(e);
        }
      });
    },
    retry: 2,
    onSuccess: (response) => {
      dispatch({ type: 'setCancellation', cancellation: { ...response, executed: true } });
      /**
       * Sending event to GTM
       */
      gtmTrack(TrackEvents.CANCEL_CLICKED, {
        position: cancellation?.reasonSelected?.position,
        orderId: cancellation?.orderDetail?.orderId,
        cancel_reason: cancellation?.reasonSelected?.message,
        origin: flow,
        selectedRefundMethod: cancellation?.selectedRefundMethod,
      });
      const url = Routes.CANCELlATION_DETAIL.replace(
        ':orderId',
        cancellation?.orderDetail?.orderId,
      );
      push(`${url}?flow=${flow}`);
    },
    onError: (e) => {
      sentryHub.setTag('transaction', 'CancelOrderError');
      sentryHub.captureException(e);
      push(Routes.CANCELLATION_HELP);
    },
  });

  return {
    isLoading,
    isError,
    mutate,
  };
};
