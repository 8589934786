import React, { useReducer, useEffect } from 'react';
import { CancellationContext, InitialContext } from './context';
import { cancellationReducer } from './reducer';
import PropTypes from 'prop-types';

const CancellationProvider = ({ children }) => {
  const [cancellation, dispatch] = useReducer(cancellationReducer, InitialContext);

  const contextValue = {
    cancellation,
    dispatch,
  };

  return (
    <CancellationContext.Provider value={contextValue}>{children}</CancellationContext.Provider>
  );
};

CancellationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CancellationProvider };
