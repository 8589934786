import React, { useMemo } from 'react';
import { Spinner } from '@appscore/web-components';
import { Switch, Route } from 'react-router-dom';
import { Routes } from '@app/config/routes';
import { useCancellationContext } from '../contexts/Cancellation';
import loadable from '@loadable/component';
/**
 * Components
 */
import NotFound from '@app/components/NotFound';
import ProtectedRoute from '@app/components/ProtectedRoute';
/**
 * Pages
 */
import Retention from '@app/pages/Retention/Retention';
const Executed = loadable(() => import('../pages/Executed/Executed'), { fallback: <Spinner /> });
const Reason = loadable(() => import('../pages/Reason/Reason'), { fallback: <Spinner /> });
const Detail = loadable(() => import('../pages/Detail/Detail'), { fallback: <Spinner /> });
const Help = loadable(() => import('../pages/Help/Help'), { fallback: <Spinner /> });
const AskUser = loadable(() => import('../pages/AskUser/AskUser'), { fallback: <Spinner /> });

const MainRouter = () => {
  const { cancellation } = useCancellationContext();
  const availableAskUser = useMemo(() => {
    return (
      cancellation?.orderDetail?.refundInformation?.availableRefundMethods &&
      cancellation?.orderDetail?.refundInformation?.availableRefundMethods.length > 0
    );
  }, [cancellation]);

  return (
    <Switch>
      <Route exact path={Routes.HOME}>
        <Retention />
      </Route>
      <ProtectedRoute
        exact
        path={Routes.CANCELLATION_REASON}
        condition={cancellation?.orderDetail?.quoteId}
      >
        <Reason />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={Routes.CANCELlATION_EXECUTED}
        condition={cancellation?.cancellation}
      >
        <Executed />
      </ProtectedRoute>
      <Route exact path={Routes.CANCELlATION_DETAIL}>
        <Detail />
      </Route>
      <ProtectedRoute exact path={Routes.CANCELLATION_ASK_USER} condition={availableAskUser}>
        <AskUser />
      </ProtectedRoute>
      <Route exact path={Routes.CANCELLATION_HELP}>
        <Help />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default MainRouter;
