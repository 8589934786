/**
 * Routes
 *
 * @description const with microsite routes
 */
export const Routes = {
  HOME: '/order-cancellation/order/:orderId',
  CANCELLATION_REASON: '/order-cancellation/reason',
  CANCELlATION_EXECUTED: '/order-cancellation/executed',
  CANCELlATION_DETAIL: '/order-cancellation/detail/:orderId',
  CANCELLATION_ASK_USER: '/order-cancellation/refund-method',
  CANCELLATION_HELP: '/order-cancellation/help',
  NOT_FOUND: '/order-cancellation/404',
};
