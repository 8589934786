import React from 'react';
import PropTypes from 'prop-types';
import theme from '@appscore/web-components/theme';
import Translations from './ErrorBoundary.translation';
import { ThemeProvider } from 'styled-components';
import { ErrorPage } from '@app/components/ErrorPage';
import { sentryHub } from '@app/utils/sentry';

export const ErrorBoundary = ({ error, ...props }) => {
  if (error?.__sentry_captured__) {
    // Allow the hub to re-catch the error if the main sentry instance already caught it
    delete error.__sentry_captured__;
  }

  sentryHub.captureException(error);

  return (
    <ThemeProvider theme={theme}>
      <ErrorPage
        title={Translations.title.defaultMessage}
        subtitle={Translations.subTitle.defaultMessage}
        buttonLabel={Translations.home.defaultMessage}
      />
    </ThemeProvider>
  );
};

ErrorBoundary.propTypes = {
  error: PropTypes.instanceOf(Error),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
