import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import theme from '@appscore/web-components/theme';
import GlobalStyleSheet from '@appscore/web-components/theme/globalStyleSheet';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts'; // See preload fonts section
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import fenixTheme from '@pedidosya/web-fenix/theme';
import Main from './main.component';
import QueryClient from '@app/utils/query_client';
import { LocalStyle } from '@app/components/LocalStyle';
import { createIntlProvider, getMessages } from './contexts/IntlProvider';
import { CancellationProvider } from './contexts/Cancellation';
import { LinkProvider } from './contexts/LinkProvider';
import { SentryProvider } from '@app/contexts/SentryProvider';
import { TrackerProvider } from '@app/contexts/TrackerProvider';
import { GlobalError } from '@app/components/GlobalError';
import {
  usePublicEnv,
  PublicEnvProvider,
  publicEnvShape,
  getPublicEnv,
} from './contexts/PublicEnv';
import { AuthProvider } from '@app/contexts/AuthProvider';
import { QueryClientProvider } from 'react-query';

const IntlProvider = (props) => {
  const { locale } = usePublicEnv();
  return createIntlProvider(locale, getMessages)(props);
};

const Root = ({ context, messages, tracker, trackerConfig }) => {
  return (
    <GlobalError>
      <PublicEnvProvider context={context} getPublicEnv={getPublicEnv}>
        <AuthProvider>
          <SentryProvider>
            <ThemeProvider theme={{ ...theme, ...fenixTheme }}>
              <>
                <FenixFonts />
                <GlobalStyleSheet />
                <LocalStyle />
                <IntlProvider initialMessages={messages}>
                  <TrackerProvider tracker={tracker} config={trackerConfig}>
                    <LinkProvider>
                      <QueryClientProvider client={QueryClient}>
                        <CancellationProvider>
                          <Main />
                        </CancellationProvider>
                      </QueryClientProvider>
                    </LinkProvider>
                  </TrackerProvider>
                </IntlProvider>
              </>
            </ThemeProvider>
          </SentryProvider>
        </AuthProvider>
      </PublicEnvProvider>
    </GlobalError>
  );
};

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};

Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.shape(publicEnvShape),
  messages: PropTypes.shape({}),
  tracker: PropTypes.object,
  trackerConfig: PropTypes.object,
};

export default Sentry.withProfiler(Root);
