import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TopBar from '@app/components/TopBar';
import { Container } from '@app/components/Container';
import { InternalContainer } from './LayoutWeb.style';

const LayoutWeb = memo(function LayoutWeb({
  children,
  onBack,
  showMock,
  canvanColor,
  iconType,
  showCanvan,
  paddingBottom,
  ...props
}) {
  return (
    <main {...props}>
      <TopBar
        callback={onBack}
        showMock={showMock}
        showCanvan={true}
        canvanColor={canvanColor}
        iconType={iconType}
      />
      <Container pTop="100" pBottom="0" style={{ textAlign: 'center' }}>
        <InternalContainer>{children}</InternalContainer>
      </Container>
    </main>
  );
});

LayoutWeb.defaultProps = {
  onBack: () => {},
  children: <></>,
  showMock: true,
  showCanvan: true,
  canvanColor: 'gray',
  iconType: 'close',
  paddingBottom: 0,
};

LayoutWeb.propTypes = {
  onBack: PropTypes.func,
  children: PropTypes.node,
  showMock: PropTypes.bool,
  showCanvan: PropTypes.bool,
  canvanColor: PropTypes.oneOf(['gray', 'green']),
  iconType: PropTypes.oneOf(['close', 'back']),
};

export { LayoutWeb };
