import React, { useReducer } from 'react';
import { LinkContext } from './context';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { generateLink, ACTIONS } from '@app/config/deeplink';
import { DEVICES } from '@app/config/device';
import { useWebViewEvents } from '@app/hooks/useWebViewEvents';
import PropTypes from 'prop-types';

/**
 * LinkProvider
 *
 * @description Provider for generate link base on device and deviceOS will generate a link or deeplink
 * @param {Object} Props - React Props
 * @returns React.Node
 */
const LinkProvider = ({ children }) => {
  const { device, deviceOS } = usePublicEnv();
  const { fire } = useWebViewEvents();

  const contextValue = {
    /**
     * redirect
     *
     * @description Function for make a redirect based on device
     * @param {ACTIONS} action  - Action to generate link
     * @param {Object} params - Params required to build de url
     */
    redirect: (action, params) => {
      window.location.href = generateLink(device, deviceOS, action, params || {});
    },
    /**
     * emitEvent
     * @param {*name} name - Name of event to send to webview
     * @param {*} params - Extra params to send to webview
     */
    emitEvent: (name, params) => {
      if (DEVICES.MOBILE === device || DEVICES.WEBVIEW === device) {
        fire(name, params);
      }
    },
  };

  return <LinkContext.Provider value={contextValue}>{children}</LinkContext.Provider>;
};

LinkProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { LinkProvider };
