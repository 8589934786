import pkg from '../../package.json';

export const Urls = {
  BASE_HTTP: `/${pkg.name}/api`,
  GENERATE_QUOTE: '/generate-quote',
  SEND_CANCELLATION: '/cancel',
  REASON_CANCELLATION: '/reason',
  DETAIL_ORDER: '/detail',
  ENV: `/${pkg.name}/api/env`,
  METRICS: `/metrics`,
};
