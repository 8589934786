import React from 'react';
import styled from 'styled-components';

const InternalContainer = styled.div`
  display: inline-block;
  min-width: 800px;
  box-shadow: 0px 0px 10px #efedf0;
  background-color: #fff;
  padding: 0px 16px 16px 16px;
  margin-bottom: 32px;
`;

export { InternalContainer };
