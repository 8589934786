import React, { createContext, useContext } from 'react';

export const InitialContext = {
  orderDetail: {
    quoteId: null,
    cancelable: true,
  },
  reasons: [],
};

export const CancellationContext = createContext({ ...InitialContext });

export const useCancellationContext = () => {
  return useContext(CancellationContext);
};
