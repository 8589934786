import styled, { createGlobalStyle } from 'styled-components';

import SegmaBlackWoff from '@app/assets/fonts/segma_black.woff';
import SegmaBlackWoff2 from '@app/assets/fonts/segma_black.woff2';
import SegmaBoldWoff from '@app/assets/fonts/segma_bold.woff';
import SegmaBoldWoff2 from '@app/assets/fonts/segma_bold.woff2';
import SegmaExtraLightWoff from '@app/assets/fonts/segma_extralight.woff';
import SegmaExtraLightWoff2 from '@app/assets/fonts/segma_extralight.woff2';
import SegmaLightWoff from '@app/assets/fonts/segma_light.woff';
import SegmaLightWoff2 from '@app/assets/fonts/segma_light.woff2';
import SegmaMediumWoff from '@app/assets/fonts/segma_medium.woff';
import SegmaMediumWoff2 from '@app/assets/fonts/segma_medium.woff2';
import SegmaRegularWoff from '@app/assets/fonts/segma_regular.woff';
import SegmaRegularWoff2 from '@app/assets/fonts/segma_regular.woff2';
import SegmaSemiBoldWoff from '@app/assets/fonts/segma_semibold.woff';
import SegmaSemiBoldWoff2 from '@app/assets/fonts/segma_semibold.woff2';
import SegmaThinWoff from '@app/assets/fonts/segma_thin.woff';
import SegmaThinWoff2 from '@app/assets/fonts/segma_thin.woff2';

export const LocalStyle = createGlobalStyle`
  body, html {
    background-color: #FFF;
  }
  @font-face {
    font-family: 'SegmaBlack';
    src: url('${SegmaBlackWoff2}') format('woff2'),
        url('${SegmaBlackWoff}'') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'SegmaBold';
    src: url('${SegmaBoldWoff2}') format('woff2'),
        url('${SegmaBoldWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'SegmaExtraLight';
    src: url('${SegmaExtraLightWoff2}') format('woff2'),
        url('${SegmaExtraLightWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'SegmaLight';
    src: url('${SegmaLightWoff2}') format('woff2'),
         url('${SegmaLightWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'SegmaMedium';
    src: url('${SegmaMediumWoff2}') format('woff2'),
         url('${SegmaMediumWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'SegmaRegular';
    src: url('${SegmaRegularWoff2}') format('woff2'),
         url('${SegmaRegularWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
  } 
  @font-face {
    font-family: 'SegmaSemibold';
    src: url('${SegmaSemiBoldWoff2}') format('woff2'),
        url('${SegmaSemiBoldWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'SegmaThin';
    src: url('${SegmaThinWoff2}') format('woff2'),
         url('${SegmaThinWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  button {
    font-family: 'SegmaBold' !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  * {
    margin: 0;
    padding: 0;
  }
`;
