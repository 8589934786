import { ExternalUrls } from '@app/config/externalUrls';
import { ExternalHttpClient } from '@app/utils/http';

const ExternalRepository = {
  /**
   * getRedirectLogin
   *
   * @description Method for get url redirect when user is no logged
   */
  getRedirectLogin: async () => {
    const result = await ExternalHttpClient.getInstance().get(`${ExternalUrls.LOGIN}`);
    return result?.data;
  },
};

export { ExternalRepository };
