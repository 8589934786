import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Shadow = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.6);
`;

const Shape = ({ children }) => {
  return <Shadow data-testid="shape">{children}</Shadow>;
};

Shape.defaultProps = {};

Shape.propTypes = {
  children: PropTypes.node,
};

export default Shape;
