import React, { useState } from 'react';
import { Switch } from '@appscore/web-components';
import { useHistory } from 'react-router-dom';
import { getItem, setItem } from '@app/utils/storage';
import { Constants } from '@app/config/constants';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const MockContainer = styled.div`
  display: flex;
  padding-right: 16px;
  & > span:first-child {
    ${(props) => props.theme.fontFamily.secondaryFont.mediumRegular};
    margin-top: 4px;
    margin-right: 8px;
  }
`;

const getInitialValue = () => {
  try {
    const value = getItem(Constants.HANDLE_MOCK);
    return value && value === 'false' ? false : true || true;
  } catch (e) {}
};

const MockSwitch = () => {
  const { go } = useHistory();
  const [state, setState] = useState(getInitialValue());

  const onChange = (state) => {
    setState(state);
    setItem(Constants.HANDLE_MOCK, state);
    go(0);
  };

  return (
    <MockContainer>
      <span>Mock</span>
      <Switch checked={state} onPress={onChange} />
    </MockContainer>
  );
};

export default MockSwitch;
