export const TrackEvents = {
  RETENTION_LOADED: 'cancel_my_order_retention.loaded',
  REASON_LOADED: 'cancel_my_order_reasons.loaded',
  CANCEL_CLICKED: 'cancel_my_order_confirm.clicked',
  FEEDBACK_CLOSED: 'cancel_my_order_feedback.closed',
  HELP_CLOSED: 'cancel_my_order_error.closed',
  CANCEL_REMEDY: 'cancel_remedy.clicked',
  DETAIL_LOADED: 'cancel_detail.loaded',
  DETAIL_CLICKED: 'cancel_detail.clicked',
};
