import { useState, useEffect } from 'react';
import { getWindow } from '@app/utils/window';

function getWindowSize() {
  const wn = getWindow();
  const { innerWidth: width, innerHeight: height } = wn ? wn : { innerWidth: 0, innerHeight: 0 };
  return {
    width,
    height,
  };
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
