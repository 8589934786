import React, { Component } from 'react';
import theme from '@appscore/web-components/theme';
import { ThemeProvider } from 'styled-components';
import { getWindow } from '@app/utils/window';
import { sentryHub } from '@app/utils/sentry';
import { ErrorPage } from '@app/components/ErrorPage';

export class GlobalError extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, hasError: false };
  }

  componentDidCatch(error) {
    this.setState({ error, hasError: true });
    if (error?.__sentry_captured__) {
      // Allow the hub to re-catch the error if the main sentry instance already caught it
      delete error.__sentry_captured__;
    }
    if (sentryHub) {
      sentryHub.captureException(error);
    }
  }

  reload() {
    const wn = getWindow();
    if (wn) {
      wn.location.reload();
    }
  }

  render() {
    const { error, hasError } = this.state;
    if (hasError) {
      return (
        <ThemeProvider theme={theme}>
          <ErrorPage />
        </ThemeProvider>
      );
    } else {
      return <>{this.props.children}</>;
    }
  }
}
