/**
 * AuthProvider
 *
 * @descrition Provider for check if a user is logged, otherwise
 * will redirect to login page (only applied for web version)
 */

import React, { useEffect, useState, useCallback } from 'react';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { ExternalRepository } from '@app/repository/external';
import { Spinner } from '@appscore/web-components';
import useIsWebView from '@app/hooks/useIsWebView';

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const env = usePublicEnv();
  const isWebView = useIsWebView();

  const urlRedirect = useCallback(async () => {
    try {
      const result = await ExternalRepository.getRedirectLogin();
      window.location.href = result?.redirectUrl;
    } catch (e) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (env && !isWebView) {
      if (!env?.userId) {
        urlRedirect();
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [env, isWebView, urlRedirect]);

  if (loading) {
    return <Spinner />;
  }

  return <>{children}</>;
};

export { AuthProvider };
