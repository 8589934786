import { useState, useCallback, useEffect, useMemo } from 'react';
import { generateQuote, cancelQuote } from '@app/service/cancellationService';
import { useParams, useHistory } from 'react-router-dom';
import { useCancellationContext } from '@app/contexts/Cancellation';
import { sentryHub } from '@app/utils/sentry';
import { useUrlQuery } from '@app/hooks/useUrlQuery';
import { useRetentionEvents } from './useRetentionEvents';
import { useQuery } from 'react-query';
import { usePub } from '@app/hooks/usePubSub';
import { PubSubEvents } from '@app/config/pubSubEvents';
import { Constants } from '@app/config/constants';
import { TrackEvents } from '@app/config/trackEvents';
import { useTracker } from '@app/contexts/TrackerProvider';
import { useConfirmQuote } from '@app/hooks/useConfirmQuote';
/**
 * useQuestion
 *
 * @description Hook for manage all business logic of view Cancellation Questions
 * @returns function
 */
export const useRetention = () => {
  const [rules, setRules] = useState(null);
  const { orderId } = useParams();
  const { cancellation, dispatch } = useCancellationContext();
  const { push } = useHistory();
  const { gtmTrack } = useTracker();
  const Events = useRetentionEvents();
  const query = useUrlQuery();
  const flow = useMemo(() => query.get('flow') || Constants.ORIGEN, []);
  const Pub = usePub();
  const { isLoading: loadingCancel, mutate } = useConfirmQuote();
  const { data, isLoading, isError } = useQuery(
    ['quote'],
    ({ signal }) => {
      return generateQuote(orderId, flow);
    },
    {
      retry: 2,
      onSuccess: (response) => {
        setRules(response?.config?.retention);
        Pub(PubSubEvents.ON_LOAD_RETENTION, {
          context: { orderId, flow },
          result: response,
        });
      },
      onError: (e) => {
        sentryHub.setTag('transaction', 'GenereateQuoteError');
        sentryHub.captureException(e);
      },
    },
  );

  useEffect(() => {
    if (gtmTrack && data) {
      gtmTrack(TrackEvents.RETENTION_LOADED, {
        origin: flow,
        orderId: orderId,
      });
    }
  }, [data, gtmTrack, orderId]);

  /**
   * onBack
   *
   * @description Function for go to order status window
   */
  const onBack = useCallback(
    (e) => {
      if (e instanceof Event) {
        e.preventDefault();
      }
      Pub(PubSubEvents.ON_BACK_ACTION_RETENTION, {});
    },
    [Pub],
  );
  /**
   * onCancel
   *
   * @description Function to go to reasons page
   */
  const onCancel = useCallback(
    (e) => {
      Pub(PubSubEvents.ON_CONTINUE_ACTION_RETENTION, {
        context: cancellation?.orderDetail,
        mutate,
      });
    },
    [cancellation?.orderDetail, mutate, Pub],
  );

  return {
    loading: isLoading,
    loadingCancel,
    error: isError,
    cancellation,
    onCancel,
    onBack,
    rules,
    flow,
  };
};
