import React from 'react';
import Error from '@pedidosya/web-fenix/pages/Error';
import useIsWebView from '@app/hooks/useIsWebView';
import { WebViewEvents } from '@app/config/webViewEvents';
import { useLinkContext } from '@app/contexts/LinkProvider';
import { ACTIONS } from '@app/config/deeplink';

const NotFound = () => {
  const { emitEvent, redirect } = useLinkContext();
  const isWebView = useIsWebView();

  const handleClose = () => {
    if (isWebView) {
      return emitEvent(WebViewEvents.CLOSE);
    }

    redirect(ACTIONS.HOME);
  };

  return <Error errorCode={404} primaryActionClick={handleClose} />;
};

export default NotFound;
