import { InitialContext } from './context';

export const cancellationReducer = (state, action) => {
  switch (action.type) {
    case 'setReasons':
      return {
        ...state,
        reasons: action.reasons.map((element) => {
          return { ...element, selected: false };
        }),
      };
    case 'setOrderDetail': {
      const detail = { ...action.detail };
      const refund = detail?.refundInformation;

      if (refund && refund?.availableRefundMethods) {
        detail.refundInformation.availableRefundMethods = refund.availableRefundMethods.map(
          (element) => {
            if (element.identifier === refund?.predefinedRefundMethod) {
              return { ...element, selected: true };
            }
            return { ...element, selected: false };
          },
        );
      }

      return {
        ...state,
        orderDetail: detail,
        extra: {},
        hasRefundMethods:
          detail?.refundInformation?.availableRefundMethods &&
          detail?.refundInformation?.availableRefundMethods.length
            ? true
            : false,
        selectedRefundMethod: refund?.predefinedRefundMethod,
      };
    }
    case 'selectReason': {
      let reasonSelected;
      const reasons = state.reasons.map((element, index) => {
        if (element.code === action.code) {
          reasonSelected = { ...element, selected: true, position: index + 1 };
          return { ...element, selected: true, showDisclaimer: true };
        }
        return { ...element, selected: false, showDisclaimer: false };
      });
      return { ...state, reasons, extra: {}, reasonSelected: reasonSelected };
    }
    case 'setCancellation': {
      return { ...state, cancellation: action.cancellation };
    }
    case 'clear': {
      return { ...InitialContext };
    }
    case 'setExtraValue': {
      return { ...state, extra: action.extra };
    }
    case 'selectRefundMethod': {
      const detail = state.orderDetail;
      const refunds = detail.refundInformation.availableRefundMethods.map((element) => {
        if (element.identifier === action.id) {
          return { ...element, selected: true };
        }
        return { ...element, selected: false };
      });
      detail.refundInformation.availableRefundMethods = refunds;
      return { ...state, orderDetail: detail, selectedRefundMethod: action.id };
    }
    case 'setExternalReason': {
      const reasons = state.reasons.map((element) => ({ ...element, selected: false }));
      state.reasons = [
        ...reasons,
        {
          selected: true,
          code: action?.orderRejectionInternalReason,
          globalRejectionReason: action?.orderRegrettedReason,
        },
      ];
      return state;
    }
    default:
      throw new Error('No action available');
  }
};
