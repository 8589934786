import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import pkg from '../package.json';
import { ErrorBoundary } from './components/ErrorBoundary';
import beforeHydratation from '@app/commons/beforeHydratation';

const rootId = `single-spa-application:${pkg.name}`;
let domElement = document.getElementById(rootId);

if (!document.body.contains(domElement)) {
  domElement = document.createElement('section');
  domElement.id = rootId;
  document.body.appendChild(domElement);
}

const lifecycles = singleSpaReact({
  domElementGetter: () => domElement,
  React,
  ReactDOM,
  renderType: () => (domElement.hasChildNodes() ? 'hydrate' : 'render'),
  loadRootComponent: async (props) => {
    const fwf = await props.fwfPromise;
    const tracker = await props.trackerPromise;
    const { App } = await beforeHydratation({ tracker, fwf });

    return App;
  },
  errorBoundary: (error) => <ErrorBoundary error={error} />,
});

export const { bootstrap, mount, unmount } = lifecycles;
