import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, createContext } from 'react';
import axios from 'axios';
import { Spinner } from '@appscore/web-components';
import { Constants } from '@app/config/constants';
import { handleItem } from '@app/utils/storage';
import { Urls } from '@app/config/urls';
import { ErrorBoundary } from '@app/components/ErrorBoundary';

const PublicEnvContext = createContext();

export const getPublicEnv = async () => {
  const { data } = await axios.get(`${Urls.ENV}`);
  handleItem(Constants.HANDLE_MOCK, data.mock === true ? false : false);
  return data;
};

export const usePublicEnv = () => {
  return useContext(PublicEnvContext);
};

export const PublicEnvProvider = ({ children, context, getPublicEnv }) => {
  const [publicEnv, setPublicEnv] = useState(context);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (publicEnv) {
      handleItem(Constants.HANDLE_MOCK, publicEnv.mock === true ? false : false);
      return;
    }
    getPublicEnv().then(setPublicEnv).catch(setError);
  }, [getPublicEnv, publicEnv]);

  if (error) return <ErrorBoundary error={error} />;

  if (!publicEnv) return <Spinner />;

  return <PublicEnvContext.Provider value={publicEnv}>{children}</PublicEnvContext.Provider>;
};

export const publicEnvShape = {
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  deviceOS: PropTypes.string.isRequired,
  mock: PropTypes.bool.isRequired,
};
