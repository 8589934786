import React, { memo, useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TrackerContext } from './context';
import { initialize as initializeTracker, track } from '@pedidosya/web-native-bridges/tracker';
import isEmpty from 'lodash/isEmpty';

const TrackerProvider = memo(function TrackerProvider({ children, tracker, config }) {
  const [trackerInstance, setTracker] = useState(tracker);

  const loadTracker = useCallback(async () => {
    try {
      await initializeTracker(config);
      setTracker({ track });
    } catch (e) {
      return null;
    }
  }, [config]);

  useEffect(() => {
    if (!isEmpty(trackerInstance)) {
      return;
    }

    loadTracker();
  }, [trackerInstance, loadTracker]);

  const value = useMemo(
    () => ({
      gtmTrack: trackerInstance?.track,
    }),
    [trackerInstance],
  );

  return <TrackerContext.Provider value={value}>{children}</TrackerContext.Provider>;
});

TrackerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  tracker: PropTypes.object,
  config: PropTypes.object.isRequired,
};

export { TrackerProvider };
