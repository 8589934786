import { Urls } from '../config/urls';
import { Constants } from '@app/config/constants';
import { getItem } from '@app/utils/storage';
import Client from '../commons/http';

const CancellationRepository = {
  /**
   * Response of service that create a quote
   * @typedef {Object} quoteResponse
   * @property {string} quoteId - Quote id after created
   * @property {string} orderId - Order id
   * @property {string} created - Date of creation
   * @property {string} status - Status of quote
   * @property {string} countryCode - Code of country
   * @property {string} expireAt - Date of expiration
   *
   */

  /**
   * generateQuote
   *
   * @description Method for generate a quote
   * @param {string} orderId - Order id
   * @param {string} flow - Journey where start the cancellation process
   * @return {Promise<quoteResponse>} - A Promise of quote
   */
  generateQuote: (orderId, flow) => {
    return Client.getInstance().get(
      `${Urls.GENERATE_QUOTE}/${orderId}?flow=${flow}&mock=${getItem(
        Constants.HANDLE_MOCK,
        false,
      )}`,
    );
  },
  /**
   * cancelQuote
   *
   * @description Method for cancel the order
   * @param {string} orderId - Order Id
   * @param {string} quoteId - Quote id
   * @param {string} reason - Id of reason cancel
   * @param {string} flow - Journey where start the cancellation process
   * @param {Object} extra - Extra field to send
   * @returns Promise
   */
  cancelQuote: (orderId, quoteId, reason, flow, extra) => {
    return Client.getInstance().post(
      `${Urls.SEND_CANCELLATION}/${orderId}?flow=${flow}&mock=${getItem(
        Constants.HANDLE_MOCK,
        false,
      )}`,
      {
        quoteId,
        orderRejectionGlobalReason: reason,
        ...extra,
      },
    );
  },
  /**
   * Response of reasons services
   * @typedef {Object} reasonResponse
   * @property {boolean} code - Code of reason
   * @property {boolean} globalRejectCode - Global reject code
   *
   */

  /**
   * getReasons
   *
   * @description Method for fetch reasons for cancellation
   * @param {string} orderId - Order Id
   * @return {Promise<reasonResponse[]>}
   */
  getReasons: (orderId) => {
    return Client.getInstance().get(
      `${Urls.REASON_CANCELLATION}?mock=${getItem(
        Constants.HANDLE_MOCK,
        false,
      )}&orderId=${orderId}`,
    );
  },
  /**
   * detail
   *
   * @description Method for return detail about an order
   * @param {string} orderId - Order id
   * @param {string} flow - Origin of request
   */
  detail: (orderId, flow) => {
    return Client.getInstance().get(
      `${Urls.DETAIL_ORDER}/${orderId}?mock=${getItem(Constants.HANDLE_MOCK, false)}&flow=${flow}`,
    );
  },
};

export { CancellationRepository };
