import { useMemo } from 'react';
import isFunction from 'lodash/isFunction';

function useWebViewEvents() {
  const fire = (name) => {
    /**
     * Checking if webview is a device apple and have the interface
     * for send message to window
     * @param ListenEventsInterface is a interface provided by iOS developer
     */
    if (window.webkit && window.webkit.messageHandlers) {
      const scopes = window.webkit.messageHandlers;
      if (
        scopes['ListenEventsInterface'] &&
        isFunction(scopes['ListenEventsInterface']?.postMessage)
      ) {
        scopes['ListenEventsInterface'].postMessage({ event: name });
      }
      /**
       * Checking if webview is a device android and have the interface
       * for send message to window
       * @param OrderActionsWebEvents is a interface provided by Android Developer
       */
    } else if (
      window['OrderActionsWebEvents'] &&
      isFunction(window['OrderActionsWebEvents']['sendEvents'])
    ) {
      window['OrderActionsWebEvents']['sendEvents'](JSON.stringify({ event: name }));
    }
  };

  return {
    fire,
  };
}

export { useWebViewEvents };
