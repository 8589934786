/**
 * This values come from webpack.config.js
 */
export default {
  appName: process.env.appName,
  appVersion: process.env.appVersion,
  sentryTrace: process.env.SentryTrace,
  sentryDSN: process.env.SentryDSN,
  sentryEnable: process.env.SentryEnabled,
  env: process.env.env,
};
