import { DEVICESOS, DEVICES } from './device';

export const DeepLink = {
  repeatOrder: {
    ios: 'pedidosya://repeatOrder?orderId={orderId}&partnerId={partnerId}&resetStack=true',
    android: 'pedidosya://myOrders/{orderId}',
    web: '/my-orders/repeat/order/{orderId}',
  },
  otherOrderQC: {
    ios: 'pedidosya://groceries-webview/ms-groceries-shop-list?bt={vertical}&origin=order-cancellation',
    android:
      'pedidosya://groceries-webview/ms-groceries-shop-list?bt={vertical}&origin=order-cancellation',
    web: '/restaurantes?city={city}',
  },
  otherOrder: {
    ios: 'pedidosya://verticals?bt={vertical}&origin=order-cancellation',
    android: 'pedidosya://verticals?bt={vertical}&origin=order-cancellation',
    web: '/restaurantes?city={city}',
  },
  home: {
    ios: 'pedidosya://launcher',
    android: 'pedidosya://launcher',
    web: '/',
  },
  orderStatus: {
    ios: 'pedidosya://orderStatus?orderId={id}',
    android: 'pedidosya://orderStatus?orderId={id}',
    web: '/order-state?code={code}&origin=myOrders&c={country}',
  },
  helpCenter: {
    ios: 'pedidosya://onlinehelp?order_id={id}&origin=order-cancellation&page_id=orderDetail',
    android: 'pedidosya://onlinehelp?order_id={id}&origin=order-cancellation&page_id=orderDetail',
    web: '/customer-service?orderId={id}&origin=order-cancellation&page_id=orderDetail',
  },
  helpCenterQA: {
    ios: 'pedidosya://onlinehelp',
    android: 'pedidosya://onlinehelp',
    web: '/customer-service',
  },
  addItems: {
    ios: 'pedidosya://orderactions/itemsmodification?orderId={orderId}&flow=order-cancellation&action=add-items',
    android:
      'pedidosya://orderactions/itemsmodification?orderId={orderId}&flow=order-cancellation&action=add-items',
  },
  changeAddress: {
    ios: 'pedidosya://orderactions/changeorderaddress?orderId={orderId}&flow=order-cancellation',
    android:
      'pedidosya://orderactions/changeorderaddress?orderId={orderId}&flow=order-cancellation',
  },
  coupons: {
    ios: 'pedidosya://mycoupons',
    android: 'pedidosya://mycoupons',
    web: '/vouchers-wallet',
  },
  wallet: {
    ios: 'pedidosya://wallet/home',
    android: 'pedidosya://wallet/home',
    web: '',
  },
};

export const ACTIONS = {
  REPEAT_ORDER: 'repeatOrder',
  OTHER_ORDER: 'otherOrder',
  OTHER_ORDER_QC: 'otherOrderQC',
  HOME: 'home',
  ORDER_STATUS: 'orderStatus',
  HELP_CENTER: 'helpCenter',
  HELP_CENTER_QA: 'helpCenterQA',
  ADDITEMS: 'addItems',
  CHANGEADDRESS: 'changeAddress',
  COUPONS: 'coupons',
  WALLET: 'wallet',
};

/**
 * generateDeepLink
 *
 * @description Function for generate deeplink based on os param
 * @param {string} action - Action to generate deep link
 * @param {string} os - OS of device (Ios | AndroidOS)
 * @param {Object} params - Any param to replace in final deeplink
 * @returns {string}
 */
export const generateDeepLink = (action, os, params) => {
  switch (os) {
    case DEVICESOS.ANDROID:
      return replaceAll(DeepLink[action].android, params);
    case DEVICESOS.IOS:
      return replaceAll(DeepLink[action].ios, params);
    default:
      return replaceAll(DeepLink[action].web, params);
  }

  function replaceAll(str, params) {
    let finalStr = str;
    for (const [key, value] of Object.entries(params)) {
      finalStr = finalStr.replace(`{${key}}`, value);
    }
    return finalStr;
  }
};

/**
 * generateLink
 *
 * @description Function for genereate link or deeplink in base to platform
 * @param {string} device - Name of device
 * @param {string} deviceOS - Operative System of device
 * @param {Object} params - Object with parameters to build final urls
 */
export const generateLink = (device, deviceOS, action, params) => {
  if (DEVICES.MOBILE === device || DEVICES.WEBVIEW === device) {
    return generateDeepLink(action, deviceOS, params);
  } else {
    return generateDeepLink(action, DEVICESOS.WEB, params);
  }
};
