import React from 'react';
import styled from 'styled-components';

const ImageContainerDiv = styled.div`
  text-align: center;
  position: relative;
  z-index: 2;
  padding-top: ${(props) => props.theme.spaces.space_32};
`;

/**
 * ImageContainer
 * @description Function for return a React.Node with wrapper for image
 * @returns React Node
 */
const ImageContainer = ({ children }) => {
  return <ImageContainerDiv data-testid="image-container">{children}</ImageContainerDiv>;
};

export default ImageContainer;
