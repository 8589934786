import React, { useMemo } from 'react';
import { useWindowSize } from '@app/hooks/useWindowSize';
import { Constants } from '@app/config/constants';

const useIsWeb = () => {
  const { width } = useWindowSize();

  return useMemo(() => (width > Constants.LAYOUT_WEB_MIN_WIDTH ? true : false), [width]);
};

export { useIsWeb };
