import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  & > main {
    min-height: 80vh;
  }
`;

export { Container };
