import React from 'react';
import { defineMessages } from 'react-intl';

export default defineMessages({
  mainTitle: {
    id: 'cancellation.questions.mainTitle',
    defaultMessage: 'Te devolveremos los {value} que pagaste',
  },
  'cancellation.questions.mainTitle': {
    id: 'cancellation.questions.mainTitle',
    defaultMessage: 'Te devolveremos los {value} que pagaste',
  },
  'cancell.question.mainTitleCash': {
    id: 'cancell.question.mainTitleCash',
    defaultMessage: '¿Quieres continuar con la cancelación?',
  },
  mainSecondTitle: {
    id: 'cancellation.questions.mainSecondTitle',
    defaultMessage: 'El restaurante pronto confirmar\u00e1 tu pedido, ¿quieres cancelarlo igual?',
  },
  backToOrder: {
    id: 'OA_cancellation_retention_fullScreen_secondaryButton_backToOrder',
    defaultMessage: 'Volver al pedido',
  },
  cacncel: {
    id: 'OA_cancellation_retention_fullScreen_mainButton_confirm',
    defaultMessage: 'Continuar cancelación',
  },
  loadingAvailabilty: {
    id: 'cancellation.questions.loadingAvailabilty',
    defaultMessage: 'Comprobando opciones de cancelaci\u00f3n',
  },
  error: {
    id: 'cancellation.questions.error',
    defaultMessage: 'En este momento no tenemos opciones de cancelaci\u00f3n para ti',
  },
  'cancellation.questions.restaurantTitle': {
    id: 'cancellation.questions.restaurantTitle',
    defaultMessage: 'El restaurante pronto confirmará tu pedido, ¿quieres cancelarlo igual?',
  },
  'cancellation.questions.allTitle': {
    id: 'cancellation.questions.allTitle',
    defaultMessage: 'El local pronto confirmará tu pedido, ¿quieres cancelarlo igual?',
  },
  'cancellation.questions.confirmedRestaurantTitle': {
    id: 'cancellation.questions.confirmedRestaurantTitle',
    defaultMessage: 'El restaurante ya está preparando tu pedido, ¿quieres cancelarlo igual?',
  },
  'cancellation.questions.confirmedAllTitle': {
    id: 'cancellation.questions.confirmedAllTitle',
    defaultMessage: 'El local ya está preparando tu pedido, ¿quieres cancelarlo igual?',
  },
  'cancellation.question.omad.title': {
    id: 'retention.question.omad.title',
    defaultMessage: 'Lo sentimos, {name}',
  },
  'cancellation.question.highDelay.title': {
    id: 'retention.question.highDelay.title',
    defaultMessage: 'Lamentamos la demora, {name}',
  },
  'cancellation.question.highDelay.mainTitle': {
    id: 'retention.question.highDelay.mainTitle',
    defaultMessage: 'Al cancelar tu pedido te devolveremos los {value} que pagaste.',
  },
  'cancellation.question.highDelay.cash.mainTitle': {
    id: 'retention.question.highDelay.cash.mainTitle',
    defaultMessage: 'Haremos lo posible por ofrecerte una mejor experiencia la próxima vez.',
  },
});
