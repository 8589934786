import axios from 'axios';
import { Urls } from '../config/urls';

const AxiosInstance = (function () {
  let instance = null;
  function Singleton() {
    if (instance) {
      return instance;
    }
    instance = this;
  }
  Singleton.getInstance = function () {
    if (!instance) {
      instance = axios.create({ baseURL: Urls.BASE_HTTP, timeout: 100000 });
    }
    return instance;
  };
  return Singleton;
})();

export default AxiosInstance;
