import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import MockSwitch from '@app/components/MockSwitch';
import throttle from 'lodash/throttle';
import { ArrowLeft, Close } from '@appscore/web-components';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { getWindowY } from '@app/utils/window';
import { Banner, Canvan, Item, MockContainer } from './TopBar.style';
import { useWindowSize } from '@app/hooks/useWindowSize';

/**
 * TopBar
 * @param {Object} props - Props passed to component
 * @param {string} props.iconType - Icon type: back or close
 * @param {function} props.callback - Function to execute when user press on icon
 * @param {function} props.showMock - Property for indicate if show mock componente or no (used only in dev, stg) it desapear in live
 * @param {function} props.showCanvan - Property for indicate if show a canvan (see design files)
 * @returns React Node
 */
const TopBar = ({
  iconType,
  callback,
  showMock,
  showCanvan = false,
  showIconBack,
  canvanColor = 'gray',
}) => {
  const [icon, setIcon] = useState(iconType);
  const [shadow, setShadow] = useState(false);
  const { mock } = usePublicEnv();
  const { width } = useWindowSize();

  const onClickIcon = (e) => {
    if (e instanceof Event) {
      e.preventDefault();
    }
    callback && callback(e);
  };

  const checkScroll = useCallback(
    (e) => {
      const y = getWindowY(0);
      setShadow(y === 0 ? false : true);
    },
    [setShadow],
  );

  useEffect(() => {
    const handleScroll = throttle((e) => {
      checkScroll();
    }, 500);

    if (typeof window !== 'undefined' && window.document?.createElement) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  });

  return (
    <Banner shadow={shadow && !showCanvan} showCanvan={showCanvan}>
      <Item onClick={onClickIcon} data-testid="topbar_item">
        {showIconBack &&
          (icon === 'back' ? (
            <span data-testid="topbar_icon_arrow">
              <ArrowLeft size="large" color="default" />
            </span>
          ) : (
            <span data-testid="topbar_icon_close">
              <Close size="large" color="default" data-testid="topbar_icon_close" />
            </span>
          ))}
      </Item>
      {mock && showMock && (
        <MockContainer data-testid="mock_container">
          <MockSwitch />
        </MockContainer>
      )}
      {showCanvan && <Canvan width={width} data-testid="canvan" canvanColor={canvanColor} />}
    </Banner>
  );
};

TopBar.defaultProps = {
  iconType: 'back',
  showMock: false,
  showCanvan: false,
  showIconBack: true,
  canvanStyle: 'gray',
};

TopBar.propTypes = {
  iconType: PropTypes.string,
  callback: PropTypes.func,
  showMock: PropTypes.bool,
  showCanvan: PropTypes.bool,
  showIconBack: PropTypes.bool,
  canvanStyle: PropTypes.string,
};

export default TopBar;
