import React, { useEffect } from 'react';
import pkg from '../../../package.json';
import { sentryHub } from '@app/utils/sentry';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import environment from '@app/config/environment';

const SentryProvider = ({ children }) => {
  const { userId, environment: env, country, device, deviceOS } = usePublicEnv();

  useEffect(() => {
    try {
      sentryHub.init({ dsn: environment.sentryDSN, environment: env });
      sentryHub.setUser({ id: userId });
      sentryHub.setTag('country', country?.shortName);
      sentryHub.setTag('templateVersion', pkg.templateVersion);
      sentryHub.setTag('origin_env', 'frontend');
      sentryHub.setTag('device', device);
      sentryHub.setTag('deviceOS', deviceOS);
    } catch (error) {
      console.error('Error initializing sentry hub', error.message);
    }
  }, [env, userId, country?.shortName, device, deviceOS]);

  return children;
};

export { SentryProvider };
