import React from 'react';
import PropTypes from 'prop-types';
import Error from '@pedidosya/web-fenix/pages/Error';
import useIsWebView from '@app/hooks/useIsWebView';
import { Container } from './ErrorPage.style';

const ErrorPage = ({ title, subtitle, buttonLabel }) => {
  const isWebView = useIsWebView();
  const deeplink = 'pedidosya://launcher';
  const webUrl = '/';

  const onBack = () => {
    const finalUrl = isWebView ? deeplink : webUrl;
    window.location.href = finalUrl;
  };

  const retry = () => {
    window.location.reload();
  };

  return (
    <Container>
      <Error
        errorCode={500}
        primaryActionLabel={'Reintentar'}
        primaryActionClick={retry}
        secondaryActionLabel={buttonLabel}
        secondaryActionClick={onBack}
      />
    </Container>
  );
};

ErrorPage.defaultProps = {
  title: '',
  subtitle: '',
  buttonLabel: '',
};

ErrorPage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonLabel: PropTypes.string,
};

export { ErrorPage };
