import { useMemo } from 'react';
import { useCancellationContext } from '@app/contexts/Cancellation';
import { useLinkContext } from '@app/contexts/LinkProvider';
import { WebViewEvents } from '@app/config/webViewEvents';
import { useHistory } from 'react-router-dom';
import { Constants } from '@app/config/constants';
import { sendEvent } from '@app/service/metricsService';
import { MetricsEvents } from '@app/config/metricsEvents';
import { Routes } from '@app/config/routes';
import { useUrlQuery } from '@app/hooks/useUrlQuery';
import { useSub } from '@app/hooks/usePubSub';
import { PubSubEvents } from '@app/config/pubSubEvents';
import useIsWebView from '@app/hooks/useIsWebView';

const useRetentionEvents = () => {
  const { dispatch } = useCancellationContext();
  const { emitEvent } = useLinkContext();
  const { goBack, push } = useHistory();
  const query = useUrlQuery();
  const isWebView = useIsWebView();
  const flow = useMemo(() => query.get('flow') || Constants.ORIGEN, []);

  /**
   * Subscription for listen event when
   * page is totally loaded
   *
   * @property {Object} data - payload
   * @property {Object} data.context - Context
   * @property {Object} data.result - Creation quote's request result
   */
  useSub(PubSubEvents.ON_LOAD_RETENTION, (data) => {
    const { context, result } = data;
    dispatch({
      type: 'setOrderDetail',
      detail: { ...result, orderId: context?.orderId, flow: context?.flow },
    });
    /**
     * If exist globalReasons and local in the response
     * save like selected reason in the store
     */
    if (result?.orderRejectionInternalReason && result?.orderRegrettedReason) {
      dispatch({
        type: 'setExternalReason',
        orderRejectionInternalReason: result?.orderRejectionInternalReason,
        orderRegrettedReason: result?.orderRegrettedReason,
      });
      dispatch({ type: 'selectReason', code: result?.orderRejectionInternalReason });
    }

    sendEvent(MetricsEvents.RETENTION_LOADED);
  });

  useSub(PubSubEvents.ON_BACK_ACTION_RETENTION, () => {
    if (isWebView) {
      return emitEvent(WebViewEvents.CLOSE);
    }
    return goBack();
  });

  useSub(PubSubEvents.ON_CONTINUE_ACTION_RETENTION, (data) => {
    const { context, mutate } = data;
    /**
     * If exist globalReasons and local reason set in store after request
     * to backend to create a quote, must be execute cancellation, because
     * this order from helpcenter
     */
    if (context?.orderRegrettedReason && context?.orderRejectionInternalReason) {
      const refunds = context?.refundInformation;
      if (
        refunds &&
        refunds?.availableRefundMethods &&
        refunds?.availableRefundMethods.length > 0
      ) {
        dispatch({ type: 'selectReason', code: context?.orderRejectionInternalReason });
        return push(`${Routes.CANCELLATION_ASK_USER}?flow=${flow}`);
      }

      return mutate({
        orderId: context?.orderId,
        quoteId: context?.quoteId,
        regretReason: context?.orderRegrettedReason,
        flow,
        internalReasons: context?.orderRejectionInternalReason,
      });
    }

    return push(`${Routes.CANCELLATION_REASON}?flow=${flow}`);
  });
};

export { useRetentionEvents };
