import { Urls } from '../config/urls';
import Client from '../commons/http';

const MetricsRepository = {
  /**
   * sendEvent
   *
   * @description Method for call service of metrics
   * @param {string} event - Event name to increment counter in DD
   */
  sendEvent: (event) => {
    return Client.getInstance().post(`${Urls.METRICS}`, { event });
  },
};

export { MetricsRepository };
