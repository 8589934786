import { CancellationRepository } from '@app/repository/cancellationRepository';
/**
 * Response of service that create a quote
 * @typedef {Object} quoteResponse
 * @property {string} quoteId - Quote id after created
 * @property {string} orderId - Order id
 * @property {string} created - Date of creation
 * @property {string} status - Status of quote
 * @property {string} totalAmount - Total amount of order
 * @property {string} businessType - Vertical
 * @property {string} userMail - User email
 * @property {Object} config - Rules confing
 * @property {Object} config.retention - Retention rule config
 * @property {string} config.retention.mainText - Translation key
 * @property {string} config.retention.mainTitle - Translation key
 * @property {Object} config.retention.navigation - Navigation configuiration
 * @property {string|null} config.retention.navigation.onPageLoadRedirect - Navigation route for redirect when page load
 * @property {string|null} config.retention.navigation.onNextPageRedirect - Navigation route for redirect when user make click on CTA
 */

/**
 * generateQuote
 *
 * @description Method for generate a quote
 * @param {string} orderId - Order id
 * @param {string} flow - Journey where start the cancellation process
 * @return {Promise<quoteResponse>} - A Promise of quote
 */
export async function generateQuote(orderId, flow) {
  try {
    const result = await CancellationRepository.generateQuote(orderId, flow);
    const data = result.data;
    const extra = result.data?.extraParams;
    return {
      quoteId: data.quoteId,
      code: extra?.orderCode,
      city: getCityFromUrl(extra?.vendorInformation?.url),
      countryCode: data?.countryCode ? data?.countryCode.toLowerCase() : '',
      userMail: extra.userMail || '',
      businessType: extra.businessType || '',
      totalAmount: extra.totalAmount || 0,
      currencySymbol: extra.currencySymbol || '',
      orderStatusState: extra.orderStatusState || '',
      vendorInformation: extra.vendorInformation || {},
      paymentFlow: extra?.paymentFlow,
      refundInformation: extra?.refundInformation,
      orderRegrettedReason: extra?.selectedReason?.globalReason,
      orderRejectionInternalReason: extra?.selectedReason?.internalReason,
      user: extra?.user,
      config: data?.config,
    };
  } catch (e) {
    throw e;
  }
}

/**
 * Response of reasons services
 * @typedef {Object} reasonResponse
 * @property {boolean} code - Code of reason
 * @property {boolean} globalRejectCode - Global reject code
 *
 */

/**
 * getReasons
 *
 * @description Method for fetch reasons for cancellation
 * @param {string} orderId - Order Id
 * @return {Promise<reasonResponse[]>}
 */
export async function getReasons(orderId) {
  try {
    const result = await CancellationRepository.getReasons(orderId);
    return result.data;
  } catch (e) {
    throw e;
  }
}

/**
 * cancelQuote
 *
 * @description Function for cancel and quote
 * @param {string} orderId - Id of order
 * @param {string} quoteId - Id of quote
 * @param {string} code - code of reason
 * @param {string} flow - Journey where start the cancellation process
 * @param {Object} extra - Extra field to send
 */
export async function cancelQuote(orderId, quoteId, code, flow, extra) {
  try {
    const result = await CancellationRepository.cancelQuote(orderId, quoteId, code, flow, extra);
    return result.data;
  } catch (e) {
    throw e;
  }
}

/**
 * detail
 *
 * @description Function to get detail about an order
 * @param {string} orderId - Order id
 * @param {string} flow - Request origin
 * @returns {Promise<Object>}
 */
export async function detail(orderId, flow) {
  const result = await CancellationRepository.detail(orderId, flow);
  return result.data;
}

function getCityFromUrl(url) {
  if (!url) {
    return '';
  }
  const regex = new RegExp('/([A-Za-z]+)/([a-zA-Z]+)/([a-zA-z0-9-]+)$', 'gi');
  if (regex.test(url)) {
    return url.split('/')[2];
  }

  return '';
}
