import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TopBar from '@app/components/TopBar';
import { Container } from '@app/components/Container';

const LayoutMobile = memo(function LayoutMobile({
  children,
  onBack,
  showMock,
  showCanvan,
  canvanColor,
  iconType,
  paddingBottom,
  ...props
}) {
  return (
    <main {...props}>
      <TopBar
        callback={onBack}
        showMock={showMock}
        showCanvan={showCanvan}
        canvanColor={canvanColor}
        iconType={iconType}
      />
      <Container pTop="0" pBottom={paddingBottom}>
        {children}
      </Container>
    </main>
  );
});

LayoutMobile.defaultProps = {
  onBack: () => {},
  children: <></>,
  showMock: true,
  showCanvan: true,
  canvanColor: 'gray',
  iconType: 'close',
  paddingBottom: 16,
};

LayoutMobile.propTypes = {
  onBack: PropTypes.func,
  children: PropTypes.node,
  showMock: PropTypes.bool,
  showCanvan: PropTypes.bool,
  canvanColor: PropTypes.oneOf(['gray', 'green']),
  iconType: PropTypes.oneOf(['close', 'back']),
  paddingBottom: PropTypes.number,
};

export { LayoutMobile };
