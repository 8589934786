import React from 'react';
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'error.title',
    defaultMessage: 'Tuvimos un problema',
  },
  subTitle: {
    id: 'error.subtitle',
    defaultMessage: 'Parece que no podemos atender tu solicitud en este momento.',
  },
  home: {
    id: 'error.backHome',
    defaultMessage: 'Volver al inicio',
  },
});
