import { MetricsRepository } from '@app/repository/metricsRepository';

/**
 * sendEvent
 *
 * @description Method for send metrics to DD
 * @param {string} event - Event name
 */
export function sendEvent(event) {
  return MetricsRepository.sendEvent(event);
}
